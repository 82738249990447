<template>
  <div class="mem-view challenges-view">
    <div class="page-header">
      <div class="header-row">
        <div class="back-button" @click="$router.push({ path: '/tropaion' })">
          <div class="button-wrap">
            <i class="material-icons">keyboard_arrow_left</i>
            <span>{{ $t("back") }}</span>
          </div>
        </div>
        <div class="mem-title">{{ $t("challenges") }}</div>
      </div>
      <v-menu transition="slide-y-transition" bottom left>
        <template v-slot:activator="{ on, attrs }">
          <div class="selected-year cursor-pointer" v-bind="attrs" v-on="on">
            <span>{{ selectedYear }}</span>
            <i class="material-icons">keyboard_arrow_down</i>
          </div>
        </template>
        <div class="year-select-menu">
          <template v-for="(year, index) in availableYears">
            <div
              @click="selectedYear = year"
              class="menu-line cursor-pointer"
              :key="index"
            >
              {{ year }}
            </div>
          </template>
        </div>
      </v-menu>
    </div>
    <div class="challenges-list">
      <div v-for="(challenge, i) in filteredChallengesList" v-bind:key="i">
        <challenge-card :challenge="challenge" />
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import moment from "moment";

import { mapGetters, mapActions } from "vuex";

import ChallengeCard from "./components/ChallengeCard.vue";

export default {
  data: () => ({
    selectedYear: new Date().getFullYear(),
    availableYears: [2020, 2021, 2022],
  }),
  computed: {
    ...mapGetters(["challengesList"]),
    groupedChallengesList() {
      let limitedChallenges = _.filter(this.challengesList, (challenge) => {
        return challenge.start_date || challenge.end_date;
      });

      let endlessChallenges = _.filter(this.challengesList, (challenge) => {
        return !challenge.start_date && !challenge.end_date;
      });

      let selectedYearChallenges = _.filter(limitedChallenges, (challenge) => {
        let startDate = _.toNumber(moment(challenge.start_date).format("YYYY"));
        let endDate = _.toNumber(moment(challenge.end_date).format("YYYY"));

        return startDate === this.selectedYear || endDate === this.selectedYear;
      });

      return [...selectedYearChallenges, ...endlessChallenges];
    },
    filteredChallengesList() {
      let commitedList = _.filter(this.groupedChallengesList, (challenge) => {
        return (
          challenge.committed &&
          (challenge.steps_done || challenge.steps_done === 0) &&
          challenge.steps_needed &&
          challenge.steps_done !== challenge.steps_needed
        );
      });
      let availableList = _.filter(this.groupedChallengesList, (challenge) => {
        return !challenge.committed;
        // return (
        //   (!challenge.committed && !challenge.end_date) ||
        //   (!challenge.committed && moment() <= moment(challenge.end_date))
        // );
      });
      let completedList = _.filter(this.groupedChallengesList, (challenge) => {
        return (
          challenge.steps_done &&
          challenge.steps_needed &&
          challenge.steps_done === challenge.steps_needed
        );
      });
      return [...commitedList, ...availableList, ...completedList];
    },
  },
  components: {
    ChallengeCard,
  },
  methods: {
    ...mapActions(["getChallenges"]),
  },
  async mounted() {
    await this.getChallenges();
    // console.log(this.selectedYear, "YEAR");
    // console.log(this.challengesList, "challenges list");
    // console.log(this.filteredChallengesList, "filter");
    // console.log(moment("2020-12-27").add(1, "days").diff(moment(), "days"), "diff")
  },
};
</script>
<style lang="scss" scoped>
.challenges-view {
  max-width: 670px;
  .page-header {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include elementMarginBottom("M");

    @media screen and (max-width: 499px) {
      padding: 0 20px;
      flex-direction: column;

      .mem-title {
        padding-left: 0;
      }
    }
    .back-button {
      position: absolute;
      bottom: 60px;
      @media screen and (max-width: 499px) {
        display: none;
      }
      .button-wrap {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;

        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;

        // margin-bottom: 40px;
        span {
          line-height: 14px;
          margin-left: 20px;
        }

        i {
          position: absolute;
          left: -8px;
        }
      }
      @include cursorPointer;
    }
    .mem-title {
      @media screen and (max-width: 499px) {
        @include elementMarginBottom("M");
      }
    }
    .selected-year {
      height: 44px;
      border: 1px solid #818181;
      border-radius: 100px;

      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.0025em;
      font-weight: 600;

      padding-left: 23px;
      padding-right: 10px;

      display: flex;
      flex-direction: row;
      align-items: center;

      @media screen and (max-width: 499px) {
        // margin-top: 30px;
        justify-content: space-between;
      }

      i {
        margin-left: 10px;
      }
    }
  }
}
.year-select-menu {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
  ),
  #000000;
  border: 0.5px solid #4f4f4f;
  box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.5);
  border-radius: 18px;
  padding: 12px;
  .menu-line {
    height: 38px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 14px;
    color: #ffffff;
    padding: 12px 0 12px 0;
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.5s;
    text-align: center;
  }
  .menu-line:hover {
    background: rgba(255, 255, 255, 0.08);
    transform: scale(1.03);
  }
}
</style>
